<script setup>
import GuestFooter from "@/Components/GuestFooter.vue";
import LanguageMenu from "@/Components/LanguageMenu.vue";
import {usePage} from "@inertiajs/vue3";
import ApplicationMark from "@/Components/ApplicationMark.vue";

const page = usePage();

</script>

<template>
    <!DOCTYPE html>
    <html lang="de">
    <head>
        <meta charset="utf-8"/>
        <title>niCloud</title>
    </head>
    <body class="dark:bg-gray-600">
    <div style="text-align:right;padding:1rem;">
        <div class="inline-flex items-center content-between">
            <a :href="route('login')" class="btn" target="_top">{{ $t('login') }}</a>
            <LanguageMenu/>
        </div>
    </div>
    <div class="center">
        <div class="flex sm:flex-row flex-col justify-center items-center">
            <svg viewBox="0 0 99.65 99.65" xmlns="http://www.w3.org/2000/svg" v-if="!page.props.settings.logo">
                <title>ni</title>
                <path
                        d="M49.82,8A41.82,41.82,0,1,1,8,49.82,41.82,41.82,0,0,1,49.82,8m0-8A49.81,49.81,0,0,0,14.59,85.05,49.82,49.82,0,1,0,69.22,3.92,49.53,49.53,0,0,0,49.82,0Z"></path>
                <path d="M71.43,69.43a4,4,0,0,1-4-4V34.22a4,4,0,1,1,8,0V65.43A4,4,0,0,1,71.43,69.43Z"></path>
                <path
                        d="M58.63,69.43a4,4,0,0,1-4-4V49.82a2.81,2.81,0,0,1,0-.29c0-.28.22-5.21-2.75-8.37-1.83-1.95-4.68-2.94-8.47-2.94s-6.64,1-8.47,2.94c-3,3.16-2.76,8.09-2.75,8.37a2.81,2.81,0,0,1,0,.29V65.43a4,4,0,0,1-8,0V49.93C24.15,48.61,24,41.18,29,35.76c3.41-3.68,8.25-5.55,14.38-5.55s11,1.87,14.38,5.55c5,5.42,4.89,12.85,4.83,14.17v15.5A4,4,0,0,1,58.63,69.43Z"></path>
            </svg>
            <ApplicationMark class="max-h-16" v-else/>
            <div class="sm:ms-3 ms-1 mt-2 sm:mt-0 sm:text-start text-center">
                <div class="text-5xl">{{ page.props.settings.company ? page.props.settings.company : page.props.tenant }}</div>
                <div class="ms-1 text-gray-400 dark:text-gray-300 leading-3 text-sm">niCloud</div>
            </div>
        </div>
    </div>
    <GuestFooter/>
    </body>
    </html>

</template>

<style scoped>
body, html {
    height: 100%;
    width: 100%
}

body, h1, h2, h3, h4, html, p {
    margin: 0;
    padding: 0
}

body {
    color: #069;
    font-family: Asap, Helvetica, Arial, sans-serif;
    font-size: 16px
}

a {
    color: gray;
    text-decoration: none
}

@media (prefers-color-scheme: dark) {
    a {
        color: lightgray;
        text-decoration: none;
    }
}

a:hover {
    color: #069;
    text-decoration: underline
}

body.content {
    color: #456;
    line-height: 1.5
}

body.content footer {
    margin: 0 auto;
    max-width: 65rem;
    position: static
}

body.content h2 {
    color: #345;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem 0
}

body.content h3 {
    font-size: 1.25rem
}

body.content h3, body.content h4 {
    color: #345;
    font-weight: 600;
    margin: .5rem 0 1.5rem
}

body.content h4 {
    font-size: 1rem
}

body.content h3, body.content h4, body.content p {
    margin-bottom: 1rem
}

header {
    background: rgba(0, 0, 0, .25);
    background: #123;
    color: #fff
}

header a, header a:hover {
    color: #fff;
    text-decoration: none
}

header .logo, header .logo svg {
    display: inline-block
}

header .logo svg {
    height: 2rem;
    vertical-align: top;
    width: 2rem
}

header .logo span {
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    margin-left: .125rem;
    margin-top: .312rem;
    text-transform: uppercase
}

header, section {
    padding: 1rem
}

section.title {
    background: #eee
}

section.main, section.title h2 {
    margin: 2rem 0
}

.container {
    margin: 0 auto;
    max-width: 64rem
}

.center {
    color: #069;
    display: block;
    height: 7rem;
    margin-top: -3.5rem;
    position: absolute;
    top: 50%;
    width: 100%
}

h1 {
    font-size: 3rem;
    font-weight: 300;
    margin-bottom: 2rem;
    padding-left: .75rem;
    padding-top: 1.25rem;
    text-transform: uppercase;
    vertical-align: baseline
}

h1, svg {
    display: inline-block
}

svg {
    fill: currentColor;
    height: 6rem;
    vertical-align: top
}

p {
    font-size: 1rem
}

.right {
    float: right
}

body.file {
    background: #123;
    color: #fff
}

body.file .center {
    height: auto;
    margin-top: -7.5rem
}

body.file svg {
    height: 3rem
}

body.file h1 {
    font-size: 1.5rem;
    padding-left: .25rem;
    padding-top: .666rem
}

body.file p {
    font-size: 1.25rem;
    padding-left: .5rem;
    padding-top: .666rem
}

body.file p strong {
    font-weight: 400
}

body.file p small {
    font-size: .75rem;
    font-weight: 200;
    opacity: .5
}

body.file button {
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    cursor: pointer;
    font-family: inherit;
    font-size: 1rem;
    padding: .5rem 1rem
}

body.file button:hover {
    background: #fff;
    color: #123
}

body.file button svg {
    height: 24px
}

body.notfound {
    background: #933;
    color: #fff
}

body.notfound .center {
    margin-top: -5rem
}
</style>
